// tailwind css
import '../css/output.css';

import Axios from 'axios';
import { useEffect } from 'react';

// components
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {PrivateRoute, AuthProvider} from './auth';
import {View} from 'components/lib';
import {NotFound} from 'views/404';
import Settings from 'settings';
if (process.env.REACT_APP_SERVER_URL) Settings[process.env.NODE_ENV].remote_server = process.env.REACT_APP_SERVER_URL;
if (process.env.REACT_APP_WEBSITE_URL) Settings[process.env.NODE_ENV].remote_client = process.env.REACT_APP_WEBSITE_URL;

const routes = [

  ...require('routes').default,

]

export default function App(props) {

  Axios.defaults.baseURL = Settings[process.env.NODE_ENV].remote_server || 'http://localhost:5000';

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');`;
    document.head.appendChild(style);
    
    return () => {
      document.head.removeChild(style);
    };
  }, []); 

  // render the routes
  return (

    <AuthProvider>
      <BrowserRouter>
        <Routes>
          {routes.map(route => {

            return (
              <Route
                key={route.path}
                path={route.path}
                element={

                  route.permission ?
                    <PrivateRoute permission={route.permission}>
                      <View display={route.view} layout={route.layout} title={route.title} />
                    </PrivateRoute> :
                    <View display={route.view} layout={route.layout} title={route.title} />

                }
              />
            )
          })}

          { /* 404 */}
          <Route path='*' element={<View display={NotFound} layout='app' title='404 Not Found' />} />

        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}
